import React from 'react'
import './App.scss'
import Principal from './principal/principal.index'

/**
 * TODO:
- Optimizacion de imágenes
- Tiempos de carga
- Avivar los colores (Verificar si lo puedo hacer en css y no editar las imágenes) Filtro

 *
 */

function App() {
  return (
    <div className="App">
      <Principal />
    </div>
  )
}

export default App
