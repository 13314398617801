import React, { Component } from 'react'
import './encabezado.scss'

export default class Encabezado extends Component {
  render() {
    return (
      <div className="slider-container">
        <div id="img1" className="imgbox">
          <div className="contenedor_textos">
            <div className="titulo">
            JAMPEM
            </div>
            <div className="subtitulo">
            Expertos en Limpieza
            </div>
          </div>
        </div>

        <div id="img2" className="imgbox">
        <div className="contenedor_textos">
        <div className="titulo">
        Productos de alta calidad
          </div>
          <div className="subtitulo">
          Nuestros productos son biodegradables, manteniendo el respeto a nuestro planeta.
          </div>
          </div>
        </div>

        <div id="img3" className="imgbox">
        <div className="contenedor_textos">
        <div className="titulo">
        Seguridad, Limpieza y Confort
          </div>
          <div className="subtitulo">
          Nuestro principal interés es lograr la satisfacción de nuestros clientes y su seguridad al utilizar nuestros productos.
          </div>
        </div>
        </div>
      </div>
    )
  }
}
