import React, { useCallback, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

interface formulario_contacto_props {
  nombre?: string
  correo?: string
  telefono?: string
  mensaje?: string
}

export const FormularioContacto = (props: formulario_contacto_props) => {
  const [token, setToken] = useState('')

  const [disableBoton, setdisableBoton] = useState(false);

  const [nombre, setNombre] = useState(props.nombre)
  const [correo, setCorreo] = useState(props.correo)
  const [telefono, setTelefono] = useState(props.telefono)
  const [mensaje, setMensaje] = useState(props.telefono)

  const clickHandler = async () => {
    try {


      if (!nombre || nombre.trim() === "") throw new Error('nombre')
      if (!correo|| correo.trim() === "") throw new Error('correo')
      if (!telefono|| telefono.trim() === "") throw new Error('teléfono')
      if (!mensaje || mensaje.trim() === "") throw new Error('mensaje')

      setdisableBoton(true);
      await sendMensaje({
        token,
        nombre,
        correo,
        telefono,
        mensaje,
      });
      setdisableBoton(false);

    } catch (err: any) {
      alert('Es necesario colocar un ' + err.message)
    }
  }

  const sendMensaje = async (obj: any) => {
    // let resp: any = await fetch('http://localhost/php/conector.php', {
    // let resp: any = await fetch('/php/conector.php', {
    let resp: any = await fetch('http://jampem.com.mx/php/conector.php', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      body: JSON.stringify({data: obj})
    });

    resp = await resp.json();

    if (resp && resp?.status === "err")
    {
      alert("El token expiró, debes recargar la página para enviar un mensaje por este medio.");
    }

    if (resp && resp?.status === "ok") {
      alert("El mensaje fue enviado correctamente");
      setNombre('');
      setCorreo('');
      setTelefono('');
      setMensaje('');
    }

    // console.log("respuesta => ", resp);
  }

  const handleReCaptchaVerify = useCallback(
    (token) => {
      console.log('Token => ', token)
      setToken(token)
    },
    [setToken]
  )

  return (
    <div className="formulario">
      <div className="control">
        <label>Nombre</label> <br />
        <input
          value={nombre}
          type="text"
          maxLength={100}
          onChange={(ev: any) => setNombre(ev.target.value ?? '')}
        />
      </div>
      <div className="control">
        <label>Correo</label> <br />
        <input
          value={correo}
          type="email"
          maxLength={100}
          onChange={(ev: any) => setCorreo(ev.target.value ?? '')}
        />
      </div>
      <div className="control">
        <label>Teléfono</label> <br />
        <input
          value={telefono}
          type="text"
          maxLength={50}
          onChange={(ev: any) => setTelefono(ev.target.value ?? '')}
        />
      </div>
      <div className="control">
        <label>Mensaje</label> <br />
        <textarea
          value={mensaje}
          rows={3}
          maxLength={500}
          onChange={(ev: any) => setMensaje(ev.target.value ?? '')}
        />
      </div>
      <div className="control">
        <button disabled={disableBoton} onClick={clickHandler}>Enviar</button>
      </div>
      <GoogleReCaptcha
        action={'jampem_contacto'}
        onVerify={handleReCaptchaVerify}
      />
    </div>
  )
}
