import React, { Component, Fragment } from 'react';
import Contacto from './contacto/contacto.index';
import Distribuidores from './distribuidores/distribuidores.index';
import Encabezado from './encabezado/encabezado.index';
import BarraMenu from './menu/menu.index';
import './principal.scss';
import Productos from './productos/productos.index';

export default class Principal extends Component {
  render() {
    return (
      <Fragment>
        <BarraMenu />
        <Encabezado />
        <Productos />
        <Distribuidores />
        <Contacto />
      </Fragment>
    )
  }
}
