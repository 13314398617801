import React, { useState } from 'react'
import Logo from './img/logo.jpg'
// import { ReactComponent as Logo } from "./img/logo.svg";
import { ReactComponent as MenuIcon } from './img/menu.svg'
import { ReactComponent as CloseMenu } from './img/x.svg'
import './menu.scss'

// import PDF from ;

const Menu = () => {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)
  return (
    <div className="header">
      <div className="logo-nav">
        <div className="logo-container">
          <div className="logo">
            <img alt="logo_jampem" src={Logo} />
          </div>
        </div>

        <div className="espaciovacio">
          <div className="contenedor">
            <div className="telefono_barra_1 telefono_1">
              <a href="tel:+525562791417"> 📞 55-62-79-14-17</a>
            </div>
            <div className="telefono_barra_1 telefono_2">
              <a href="tel:+525574715200">📞 55-74-71-52-00</a>
            </div>
          </div>
        </div>

        <ul className={click ? 'nav-options active' : 'nav-options'}>
          <li className="option" onClick={closeMobileMenu}>
            <a
              href="./archivos/catalogo/CATALOGO_JAMPEM_V1.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Catálogo
            </a>
            {/* <a
                  href={props.link_ficha ?? ''}
                  target="_blank"
                  rel="noreferrer"
                  >
                  Ficha Técnica
                </a> */}
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <a href="#productos">Productos</a>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <a href="#distribuidores">Distribuidores</a>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <a href="#contacto">Contacto</a>
          </li>
        </ul>
      </div>

      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <CloseMenu className="menu-icon" />
        ) : (
          <MenuIcon className="menu-icon" />
        )}
      </div>
    </div>
  )
}

export default Menu
