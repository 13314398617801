import React, { Fragment } from 'react'
import './producto.scss'

interface productos_datos {
  titulo_img: string
  botella_img: string
  texto_breve: any
  texto_beneficios: any
  columna_derecha: any
  tiene_aromas?: boolean

  link_ficha?: string
  link_tecnica?: string
  link_cencon?: string
}

export default function Producto(props: productos_datos) {
  return (
    <Fragment>
      <div className="producto md">
        <div className="encabezado">
          <img alt="encabezado_producto" src={props.titulo_img} />
        </div>
        <br />
        <div className="cuerpo">
          <div className="item botella">
            <img alt="botella" src={props.botella_img} />
          </div>
          <div className="item producto__texto_centro">
            <div className="texto_breve item">{props.texto_breve}</div>

            <div className="texto_beneficios item">
              <div className="titulo">Beneficios</div>
              {props.texto_beneficios}
            </div>

            <div className="descargas item">
              <div className="titulo">Descargas</div>
              {props.link_ficha ? (
                <div className="wrapperButton">
                  <a
                    className="boton"
                    href={props.link_ficha ?? ''}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ficha Técnica
                  </a>
                </div>
              ) : (
                ''
              )}
              {props.link_tecnica ? (
                <div className="wrapperButton">
                  <a
                    className="boton"
                    href={props.link_tecnica ?? ''}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ficha de Seguridad
                  </a>
                </div>
              ) : (
                ''
              )}
              {props.link_cencon ? (
                <div className="wrapperButton">
                  <a
                    className="boton"
                    href={props.link_cencon ?? ''}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ficha de CENCON
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="item producto__derecha">
            {props.columna_derecha}

            {!props.tiene_aromas ? (
              <div className="logo_covid_19 item">
                <img
                  alt="covid_19"
                  src={require('../img/covid_19.jpg').default}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div className="producto sm">
        <div className="encabezado">
          <img alt="encabezado_producto" src={props.titulo_img} />
        </div>
        <div className="contenedor">
          <div className="botella_logos">
            <div className="botella">
              <img alt="botella" src={props.botella_img} />
            </div>
            {props.columna_derecha}
            {!props.tiene_aromas ? (
              <div className="logo_covid_19 item">
                <img
                  alt="covid_19"
                  src={require('../img/covid_19.jpg').default}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="textos">
            <div className="texto_breve item">{props.texto_breve}</div>

            <div className="texto_beneficios item">
              <div className="titulo">Beneficios</div>
              {props.texto_beneficios}
            </div>
          </div>
        </div>
        <div className="descargas item">
          <div className="titulo">Descargas</div>
          {props.link_ficha ? (
            <div className="wrapperButton">
              <a
                className="boton"
                href={props.link_ficha ?? ''}
                target="_blank"
                rel="noreferrer"
              >
                Ficha Técnica
              </a>
            </div>
          ) : (
            ''
          )}
          {props.link_tecnica ? (
            <div className="wrapperButton">
              <a
                className="boton"
                href={props.link_tecnica ?? ''}
                target="_blank"
                rel="noreferrer"
              >
                Ficha de Seguridad
              </a>
            </div>
          ) : (
            ''
          )}
          {props.link_cencon ? (
            <div className="wrapperButton">
              <a
                className="boton"
                href={props.link_cencon ?? ''}
                target="_blank"
                rel="noreferrer"
              >
                Ficha de CENCON
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Fragment>
  )
}
