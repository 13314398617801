import React from 'react'
import './distribuidores.scss'

export default function Distribuidores() {

    const distribuidoresTarjetasCdmx = []

    for(let i = 1; i < 7; i++)
    {
      distribuidoresTarjetasCdmx.push(
        <div className="img-tarjeta" key={i}>
          <img
            alt="distribuidores_imagen_tarjeta"
            src={require(`./tarjetas/tarjeta_cdmx_${i}.jpeg`).default}
          />
        </div>
      )
    }

    const distribuidoresTarjetasEdoMx = []

    for(let i = 1; i < 4; i++)
    {
      distribuidoresTarjetasEdoMx.push(
        <div className="img-tarjeta" key={i}>
          <img
            alt="distribuidores_imagen_tarjeta"
            src={require(`./tarjetas/tarjeta_edomexico_${i}.jpeg`).default}
          />
        </div>
      )
    }




  return (
    <div id="distribuidores" className="distribuidores">
      <div className="titulo">Distribuidores</div>
      <div className="movil">
        <div className="img-volante">
          <img
            alt="distribuidores_volante_movil"
            src={require('./img/movil.png').default}
          />
        </div>
      </div>
      <div className="nomovil">
        <div className="img-volante">
          <img
            alt="distribuidores_volante_movil"
            src={require('./img/pc.png').default}
          />
        </div>
      </div>
      <div className="distribuidor_footer">
        <div className="subtitulo">Contáctanos</div>
        <div className="telefono">
          <a href="tel:5574715200">
            <div className="telefono__espacio">&nbsp;</div>
            <div className="telefono__numero">📞 55-7471-5200</div>
          </a>
        </div>
        <div className="whatsapp">
          <a
            href="https://api.whatsapp.com/send?phone=525574715200&text=%C2%A1Quiero%20ser%20distribuidor!"
            target="_blank"
            rel="noreferrer"
          >
            <div className="whatsapp__logo">
              <img
                alt="whatsapp_logo"
                src={require('./img/whatsapp_logo.svg').default}
              />
            </div>
            <div className="whatsapp__mensaje">Envíanos un mensaje</div>
          </a>
        </div>
      </div>

      <div className="distribuidores_tarjetas">
      <h2>Distribuidores CDMX</h2>
        <div className="distribuidores_lista">
           {distribuidoresTarjetasCdmx}
        </div>
      </div>

      <div className="distribuidores_tarjetas">
      <h2>Distribuidores Edo. de México</h2>
        <div className="distribuidores_lista">
           {distribuidoresTarjetasEdoMx}
        </div>
      </div>
    </div>
  )
}
