import React, { Component } from 'react';
import './contacto.scss';
import { FormularioContacto } from './formulario_contacto';

export default class Contacto extends Component {
  render() {
    return (
      <div id="contacto" className="contacto">
        <div className="contenedor">
          <div className="titulo">Contacto</div>
          <div className="cuerpo">
            <div className="columna_izquierda">
              <div className="horario_servicio">
                <div className="subtitulo">Horario de Servicio</div>
                <div className="datos">
                  <p>Lunes - Viernes: 9 a. m. - 6:30 p. m.</p>
                  <p>Sábado: 9 a. m. - 2 p. m.</p>
                  <p>Domingo: cerrado</p>
                </div>
              </div>
              <div className="correo_electronico">
                <div className="subtitulo">Correo electrónico</div>
                <div className="datos">
                  <p>
                    <a href="mailto:ventas@lisalim.com.mx">
                      📧 contacto@lisalim.com.mx
                    </a>
                  </p>
                </div>
              </div>
              <div className="telefonos">
                <div className="subtitulo">Teléfonos</div>
                <div className="datos">
                  <p>
                    <a href="tel:+525562791417"> 📞 55-62-79-14-17</a>
                  </p>
                  <p>
                    <a href="tel:+525574715200">📞 55-74-71-52-00</a>
                  </p>
                </div>
              </div>
              <div className="redes_sociales">
                <div className="subtitulo">Síguenos</div>
                <div className="datos">
                  <a href="https://www.facebook.com/LisalimOficial" target="_blank" rel="noreferrer"><img className="facebook_logo" src={require('./img/facebook.svg').default} alt="Facebook"></img></a>
                </div>
              </div>
            </div>

            <div className="columna_derecha">
              <FormularioContacto />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
