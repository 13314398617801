import React, { Component, Fragment } from 'react'
import Producto from './producto/producto.index'
import './productos.scss'

export default class Productos extends Component {
  render() {
    return (
      <div id="productos" className="productos">
        <div className="titulo titulo_left">Nuestros productos</div>
        <div className="titulo_right">
          <div className="btn_catalogo">
            <a  target="_blank"
                rel="noreferrer" href="./archivos/catalogo/CATALOGO_JAMPEM_V1.pdf" className="boton">
            Catálogo
            </a>
          </div>
        </div>

        <div className="lista">
          <Producto
            titulo_img={require('./img/jabon_antibacterial/titulo.jpg').default}
            botella_img={
              require('./img/jabon_antibacterial/botella.jpg').default
            }
            texto_breve={
              <Fragment>
                <span className="resaltado">Jampem - Jabón líquido</span> para
                manos con agradable aroma biodegradable, con acción germicida y
                PH balanceado que no daña las manos.
              </Fragment>
            }
            texto_beneficios={
              <ul className="lista_beneficios">
                <li>Alto rendimiento.</li>
                <li>
                  Promueve limpieza rápida y efectiva de manos en cualquier
                  área.
                </li>
                <li>
                  Gracias al PH balanceado e ingredientes de su formulación no
                  daña las grasas naturales de la piel, ni produce resequedad,
                  por lo que, se puede usar de manera frecuente en manos.
                </li>
              </ul>
            }
            columna_derecha={
              <Fragment>
                <div className="presentacion item">
                  <img
                    alt="presentacion"
                    src={
                      require('./img/jabon_antibacterial/presentacion.jpg')
                        .default
                    }
                  />
                </div>
                <div className="presentacion item">
                  <img
                    alt="presentacion"
                    src={
                      require('./img/jabon_antibacterial/aromas.jpg').default
                    }
                  />
                </div>
              </Fragment>
            }
            tiene_aromas={true}
            link_ficha="./archivos/fichatecnica/JAMPEM_JABON_ANTIBACTERIAL.pdf"
            link_tecnica="./archivos/hojadedatos/JABON_ANTIBACTERIAL_MSDS.PDF"
          />

          <Producto
            titulo_img={require('./img/gel_antibacterial/titulo.jpg').default}
            botella_img={require('./img/gel_antibacterial/botella.jpg').default}
            texto_breve={
              <Fragment>
                <span className="resaltado">Jampem - Gel desinfectante</span> a
                base de alcohol, biodegradable, con acción germicida y PH
                balanceado que no daña la piel.
              </Fragment>
            }
            texto_beneficios={
              <ul className="lista_beneficios">
                <li>
                  Su fórmula contiene triclosán y alcohol en un 70% con lo que
                  se logra eliminar el 99.99% de gérmenes.
                </li>
                <li>No mancha ni deja sensación pegajosa en las manos.</li>
                <li>
                  Contiene emolientes y humectantes que evitan la resequedad en
                  la piel, por lo que se puede usar de manera frecuente en las
                  manos.
                </li>
              </ul>
            }
            columna_derecha={
              <div className="presentacion item">
                <img
                  alt="presentacion"
                  src={
                    require('./img/gel_antibacterial/presentacion.jpg').default
                  }
                />
              </div>
            }

            link_ficha="./archivos/fichatecnica/JAMPEM_GEL_ANTIBACTERIAL.pdf"
            link_tecnica="./archivos/hojadedatos/GEL_DESINFECTANTE_MSDS.PDF"
            link_cencon="./archivos/cencon/CENCON - GELANTIBACTERIAL.pdf"
          />

          <Producto
            titulo_img={
              require('./img/sanitizante_concentrado/titulo.jpg').default
            }
            botella_img={
              require('./img/sanitizante_concentrado/botella.jpg').default
            }
            texto_breve={
              <Fragment>
                <span className="resaltado">
                  Jampem - Sanitizante concentrado
                </span>{' '}
                es un desinfectante líquido para superficies, baños, cocinas,
                pisos, paredes y drenajes. Formulado a base de cuaternarios de
                amonio, utilizado en industrias, hotelera, publica e industrial.
              </Fragment>
            }
            texto_beneficios={
              <ul className="lista_beneficios">
                <li>
                  Amplio espectro bactericida y control efectivo de hongos.
                </li>
                <li>
                  Es ideal para usarse como desinfectante sin enjuague; no
                  mancha las superficies en las concentraciones de uso.
                </li>
                <li>
                  Contiene una formula concentrada, 8 ml. por litro proporcionan
                  400 ppm de cuaternario de amonio.
                </li>
              </ul>
            }
            columna_derecha={
              <div className="presentacion item">
                <img
                  alt="presentacion"
                  src={
                    require('./img/sanitizante_concentrado/presentacion.jpg')
                      .default
                  }
                />
              </div>
            }

            link_ficha="./archivos/fichatecnica/JAMPEM_SANITIZANTE_CONCENTRADO.pdf"
            link_tecnica="./archivos/hojadedatos/SANITIZANTE_CONCENTRADO_MSDS.PDF"
          />

          <Producto
            titulo_img={require('./img/limpiador_multiusos/titulo.jpg').default}
            botella_img={
              require('./img/limpiador_multiusos/botella.jpg').default
            }
            texto_breve={
              <Fragment>
                <span className="resaltado">Jampem - Limpiador multiusos</span>{' '}
                para todo tipo de superficies y pisos que ayuda a la eliminación
                de mugre, grasa y polvo dejando un agradable aroma. Utilizado en
                industrias restaurantera, hotelera, gimnasios, escuelas e
                institucional.
              </Fragment>
            }
            texto_beneficios={
              <ul className="lista_beneficios">
                <li>
                  No requiere aditivos economizando horas en la operación de
                  mezclado.
                </li>
                <li>
                  Su formulación concentrada proporciona la mejor relación de
                  costo-beneficio a las concentraciones de empleo recomendadas.
                </li>
                <li>
                  Reduce el tiempo de limpieza debido a su rápida remoción de
                  depósitos de suciedad.
                </li>
              </ul>
            }
            columna_derecha={
              <Fragment>
                <div className="presentacion item">
                  <img
                    alt="presentacion"
                    src={
                      require('./img/limpiador_multiusos/presentacion.jpg')
                        .default
                    }
                  />
                </div>
                <div className="presentacion item">
                  <img
                    alt="presentacion"
                    src={
                      require('./img/limpiador_multiusos/aromas.jpg').default
                    }
                  />
                </div>
              </Fragment>
            }
            tiene_aromas={true}

            link_ficha="./archivos/fichatecnica/JAMPEM_LIMPIADOR_MULTIUSOS.pdf"
            link_tecnica="./archivos/hojadedatos/MULTIUSOS_MSDS.PDF"
          />

          <Producto
            titulo_img={require('./img/cloro/titulo.jpg').default}
            botella_img={require('./img/cloro/botella.jpg').default}
            texto_breve={
              <Fragment>
                <span className="resaltado">Jampem - Cloro</span> es un
                desinfectante a base de hipoclorito de sodio de alto rendimiento
                para uso en la industria hotelera, restaurantes, escuelas,
                oficinas, hospitales, etc.
              </Fragment>
            }
            texto_beneficios={
              <ul className="lista_beneficios">
                <li>Combate un amplio espectro de organismos patógenos.</li>
                <li>
                  Se enjuaga totalmente, no deja sedimentos, películas o
                  depósitos.
                </li>
                <li>
                  El exceso mínimo de alcalinidad ayuda a garantizar la descarga
                  rápida del ingrediente activo.
                </li>
                <li>
                  Uso en: Cloración de agua, frutas y legumbres y en superficies
                  porosas y no porosas
                </li>
              </ul>
            }
            columna_derecha={
              <div className="presentacion item">
                <img
                  alt="presentacion"
                  src={require('./img/cloro/presentacion.jpg').default}
                />
              </div>
            }

            link_ficha="./archivos/fichatecnica/JAMPEM_CLORO.pdf"
            link_tecnica="./archivos/hojadedatos/CLORO_MSDS.PDF"
          />

          <Producto
            titulo_img={require('./img/sanitizante/titulo.jpg').default}
            botella_img={require('./img/sanitizante/botella.jpg').default}
            texto_breve={
              <Fragment>
                <span className="resaltado">Jampem - Sanitizante</span> es un
                desinfectante líquido para superficies, baños, cocinas, pisos,
                paredes y drenajes. Formulado a base de cuaternarios de amonio,
                utilizado en industrias, hotelera, publica e industrial.
              </Fragment>
            }
            texto_beneficios={
              <ul className="lista_beneficios">
                <li>
                  Amplio espectro bactericida y control efectivo de hongos.
                </li>
                <li>
                  Es ideal para usarse como desinfectante sin enjuague; no
                  mancha las superficies en las concentraciones de uso.
                </li>
                <li>
                  Puede ser utilizado para desodorizar y desinfectar en todo
                  tipo de baños, mingitorios, lavabos y paredes.
                </li>
              </ul>
            }
            columna_derecha={
              <div className="presentacion item">
                <img
                  alt="presentacion"
                  src={require('./img/sanitizante/presentacion.jpg').default}
                />
              </div>
            }

            link_ficha="./archivos/fichatecnica/JAMPEM_SANITIZANTE.pdf"
            link_tecnica="./archivos/hojadedatos/SANITIZANTE_MSDS.PDF"
          />
        </div>
      </div>
    )
  }
}
